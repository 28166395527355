
import { computed, defineComponent, PropType } from 'vue';
import EmptyState from '@/components/EmptyState.vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    EmptyState,
  },
  props: {
    text: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props) {
    const { t } = useI18n();
    const errorMessageText = computed(() => {
      return props.text || t('common.request-error-message');
    });
    return {
      router,
      errorMessageText,
    };
  },
});
