import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!

  return (_openBlock(), _createBlock(_component_EmptyState, {
    heading: _ctx.$t('common.request-error'),
    icon: "icon-warning",
    text: _ctx.errorMessageText,
    textAtBottom: true
  }, {
    bottom: _withCtx(() => [
      _createVNode(_component_Button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.go(0)))
      }, {
        default: _withCtx(() => [
          _cache[1] || (_cache[1] = _createElementVNode("i", {
            class: "icon-redo",
            "aria-hidden": "true"
          }, null, -1)),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.reload')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["heading", "text"]))
}