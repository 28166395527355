import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_GetRequestError = _resolveComponent("GetRequestError")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_MedicalTestList = _resolveComponent("MedicalTestList")!
  const _component_MedicalTestCreateUpdateFormModal = _resolveComponent("MedicalTestCreateUpdateFormModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-bar_chart",
      description: _ctx.$t('medicalTest.information')
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.onNewMedicalTest
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('medicalTest.new')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title", "description"]),
    _createElementVNode("main", _hoisted_1, [
      (_ctx.isLoadingMedicalTests)
        ? (_openBlock(), _createBlock(_component_LoadingComponent, {
            key: 0,
            "spinner-color": "lavender-700"
          }))
        : (_ctx.isLoadingMedicalTestsErrorful)
          ? (_openBlock(), _createBlock(_component_GetRequestError, { key: 1 }))
          : (_ctx.isMedicalTestsListEmpty)
            ? (_openBlock(), _createBlock(_component_EmptyState, {
                key: 2,
                heading: _ctx.$t('medicalTest.no-data-title'),
                icon: "icon-alarm",
                text: _ctx.$t('medicalTest.no-data-message'),
                textAtBottom: true
              }, null, 8, ["heading", "text"]))
            : (_ctx.medicalTests)
              ? (_openBlock(), _createBlock(_component_MedicalTestList, {
                  key: 3,
                  patient: _ctx.patient,
                  medicalTests: _ctx.medicalTests,
                  "table-list": true,
                  onCreateMedicalTestClick: _ctx.onNewMedicalTest,
                  onMedicalTestViewDetailClick: _ctx.onSeeDetailClicked,
                  onNewMedicalTest: _ctx.fetchMedicalTest
                }, null, 8, ["patient", "medicalTests", "onCreateMedicalTestClick", "onMedicalTestViewDetailClick", "onNewMedicalTest"]))
              : _createCommentVNode("", true)
    ]),
    _createVNode(_component_MedicalTestCreateUpdateFormModal, {
      ref: "newMedicalTestModalRef",
      patient: _ctx.patient,
      medicalTestList: _ctx.medicalTests,
      onNewMedicalTest: _ctx.fetchMedicalTest
    }, null, 8, ["patient", "medicalTestList", "onNewMedicalTest"])
  ], 64))
}