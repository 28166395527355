
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import EmptyState from '@/components/EmptyState.vue';
import MedicalTestList from '@/components/MedicalTestList.vue';
import { Patient } from '@/models/Patient';
import { medicalTestService } from '@/services/MedicalTestService';
import { MedicalTestTableDto, MedicalTestTypeValue } from '@/models/MedicalTest';
import router from '@/router';
import { useRoute } from 'vue-router';

import { ServiceError } from '@/services/util/ServiceError';
import MedicalTestCreateUpdateFormModal from '@/components/MedicalTestCreateUpdateFormModal.vue';
import GetRequestError from '@/components/common/GetRequestError.vue';

export default defineComponent({
  components: {
    HeadingComponent,
    MedicalTestList,
    MedicalTestCreateUpdateFormModal,
    LoadingComponent,
    EmptyState,
    GetRequestError,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const newMedicalTestModalRef = ref();
    const medicalTests = ref();
    const isLoadingMedicalTests = ref(true);
    const isMedicalTestsListEmpty = computed(() => medicalTests.value !== undefined && medicalTests.value.length === 0);
    const isLoadingMedicalTestsErrorful = ref(false);
    const fetchMedicalTest = async () => {
      isLoadingMedicalTests.value = true;
      const result = await medicalTestService.findAll(props.patient.id);
      if (!(result instanceof ServiceError)) {
        medicalTests.value = [...result];
      } else if (result.status === 500) {
        isLoadingMedicalTestsErrorful.value = true;
      }
      isLoadingMedicalTests.value = false;
    };
    onMounted(async () => {
      await fetchMedicalTest();
    });

    const onNewMedicalTest = () => {
      newMedicalTestModalRef.value?.openDialog();
    };

    const onSeeDetailClicked = async (medicalTest: MedicalTestTableDto) => {
      if (medicalTest.medicalTestTypeValue == MedicalTestTypeValue.CALORIMETRY) {
        await router.push(`/patient/${props.patient.id}/medical-test/calorimetry/${medicalTest.id}`);
      }

      if (medicalTest.medicalTestTypeValue == MedicalTestTypeValue.CGM) {
        await router.push(`/patient/${props.patient.id}/medical-test/cgm/${medicalTest.id}/follow-up`);
      }
    };

    return {
      newMedicalTestModalRef,
      route,
      medicalTests,
      isLoadingMedicalTests,
      isMedicalTestsListEmpty,
      isLoadingMedicalTestsErrorful,
      router,
      onSeeDetailClicked,
      onNewMedicalTest,
      fetchMedicalTest,
    };
  },
});
